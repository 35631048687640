<template>
    <div>
        <div v-if="!point_id" class="d-flex flex-column align-start">
            <span class="px-2 rowItemSuccess1">Первый заказ в этой точке</span>
            <span class="px-2 rowItemSuccess2">Гость заказывает только в этой точке</span>
        </div>
        <div v-if="point_id" class="d-flex flex-column align-start">
            <span class="px-2">⭐️ - Полная связь гостя с этой точкой</span>
            <span class="px-2 rowItemWarning">Первый заказ в этой точке</span>
            <span class="px-2 rowItemSuccess1">Гость связан только с этой точкой одним заказом</span>
            <span class="px-2 rowItemSuccess2">Гость связан только с этой точкой несколькими заказами</span>
        </div>

        <v-card align="center" justify="center" class="guests-card mx-auto elevation-6 mt-2">
            <v-toolbar height="50" color="purple darken-3 elevation-3" dark flat>
                <v-toolbar-title class="overline">
                    <v-icon dense class="mr-1">mdi-star-outline</v-icon>
                    По рейтингу - гостей: {{ totalGuests }}, общий чек: {{ formatPrice(totalCost) }} ₽
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="onClickUpdate">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-title class="pt-0 pb-2 d-flex">
                <v-select v-if="!$store.state.userIsPoint" class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point" item-text="name" item-value="id" v-model="filter_point" label="По точке" hide-details></v-select>

                <v-btn-toggle v-if="$store.state.userIsPoint" v-model="toggleGuests" color="primary" dense group mandatory @change="updateData">
                    <v-btn :value="1" text>
                        Мои гости
                    </v-btn>
                    <v-btn :value="2" text>
                        Все гости
                    </v-btn>
                </v-btn-toggle>

                <v-spacer></v-spacer>

                <v-btn
                    v-if="search.length"
                    class="mt-4 mr-5"
                    color="grey darken-1"
                    outlined
                    text
                    small
                    @click="
                        search = ''
                        $refs.searchInput.focus()
                    "
                    ><v-icon>mdi-broom</v-icon></v-btn
                >

                <v-text-field ref="searchInput" style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
            </v-card-title>

            <v-data-table
                :mobile-breakpoint="0"
                :search="search"
                :options.sync="options"
                :server-items-length="totalGuests"
                dense
                no-data-text="Нет данных"
                no-results-text="Нет данных, попробуйте изменить запрос"
                :footer-props="{
                    pageText: '{0} - {1} из {2}',
                    itemsPerPageText: 'Показывать на странице',
                    itemsPerPageAllText: 'все',
                    showFirstLastPage: true,
                    itemsPerPageOptions: [25, 50, 100, 250],
                }"
                :headers="headers"
                :items="guests"
                :item-class="rowItemClass"
                item-key="index"
                :loading="loading"
                :loading-text="$t('common.loading')"
                @click:row="onClickGuest($event)"
            >
                <template v-slot:[`item.phone`]="{ item }">
                    <b>{{ item.phone }}</b>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                    <span v-if="!item.name" class="red--text">не указано</span>
                    <span v-if="item.name">{{ item.name }}</span>
                </template>

                <template v-slot:[`item.date_create`]="{ item }">
                    {{ timeToString(item.date_create) + ' ' + item.date_create_rate }}
                </template>

                <template v-slot:[`item.date_visit`]="{ item }">
                    {{ timeToString(item.date_visit) + ' ' + item.date_visit_rate }}
                </template>

                <template v-slot:[`item.points`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <td class="tooltip-text" style="min-width: 240px" v-bind="attrs" v-on="on">
                                <span>{{ item.points.substr(0, 35) }}</span>
                            </td>
                        </template>
                        <span>{{ item.points }}</span>
                    </v-tooltip>
                </template>

                <template v-slot:[`item.pay_cost`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ formatPrice(item.pay_cost) }} ₽</span>
                </template>

                <template v-slot:[`item.cost`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ formatPrice(item.cost) }} ₽</span>
                </template>

                <template v-slot:[`item.other_cost`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ formatPrice(item.other_cost) }} ₽</span>
                </template>

                <template v-slot:[`item.order_count`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ item.order_count }}</span>
                    <v-btn v-if="item.order_count" outlined text tile x-small @click="onClickOrdersDetails(item, item.order_count, true)"><v-icon small>mdi-menu</v-icon></v-btn>
                </template>

                <template v-slot:[`item.count`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ item.count + ' ' + item.count_rate }}</span>
                    <!-- <v-btn v-if="item.count" outlined text tile x-small @click="onClickOrdersDetails(item, item.count, false)"><v-icon small>mdi-menu</v-icon></v-btn> -->
                </template>

                <template v-slot:[`item.other_count`]="{ item }">
                    <span style="width:35px; display: inline-block;">{{ item.other_count }}</span>
                    <!-- <v-btn v-if="!$store.state.userIsPoint && item.other_count" outlined text tile x-small @click="onClickOrdersDetails(item, item.other_count, true)"><v-icon small>mdi-menu</v-icon></v-btn> -->
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="ordersDialog" width="720" scrollable>
            <v-card :loading="loadingOrders">
                <template slot="progress">
                    <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                </template>

                <v-card-title class="headline grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text class="py-4">
                    <div class="text-center">
                        <v-pagination v-model="page" :length="Math.ceil(orders.length / perPage)"></v-pagination>
                    </div>
                    <span v-if="loadingOrders && !visibleOrders.length">{{ $t('common.loading') }}</span>
                    <!-- <span v-if="!visibleOrders.length" class="ml-3 grey--text"><b> ------------ скрыто ------------ </b></span> -->
                    <div v-for="(order, i) of visibleOrders" :key="order.id" class="d-flex">
                        {{ (page - 1) * perPage + i + 1 }}.
                        <div v-if="orderCheckOwner(order)">
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order)"></div>
                                </v-card>
                            </v-menu>
                            <span v-html="orderListItem(order)"></span>
                        </div>

                        <div v-else class="ml-3 guest-hidden">
                            <span v-html="orderListHiddenItem(order)"></span>
                        </div>

                        <br />
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="primary" dark @click="updateOrdersDetails">Обновить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="ordersDialog = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="guestDialog" max-width="520">
            <v-card class="guest-dialog" :loading="loadingEdit">
                <template slot="progress">
                    <v-progress-linear indeterminate absolute top color="deep-purple accent-4"></v-progress-linear>
                </template>

                <v-card-title class="headline grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text class="mt-0">
                    <v-row>
                        <v-col cols="12" class="pb-0 d-flex align-center">
                            <v-avatar size="70" class="mr-1 elevation-4">
                                <img alt="guest" :src="`${config.api.delivery + 'user_avatar?tid=0'}`" />
                            </v-avatar>

                            <v-text-field :disabled="guestCopy.hide" class="my-7 mx-4 flex-grow-0" style="width: 100%" v-model="guestCopy.name" label="Имя гостя" hide-details v-on:keyup.enter="onClickSave"></v-text-field>

                            <v-btn :disabled="guestCopy.hide" color="success" dark @click="onClickSave">
                                <v-icon dense class="mr-2">mdi-content-save-outline</v-icon>
                                Сохранить
                            </v-btn>
                        </v-col>

                        <v-col cols="12" class="pb-0">
                            <v-text-field class="mx-4" disabled v-model="guestCopy.phone" label="Номер телефона"></v-text-field>
                        </v-col>

                        <v-expansion-panels class="mx-4">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Отправить SMS-сообщение
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <v-col cols="12">
                                        <v-textarea rows="2" label="Текст сообщения" id="message" name="message" type="text" v-model="sms_message" hide-details></v-textarea>
                                    </v-col>

                                    <v-col cols="12" v-if="!$store.state.userIsPoint">
                                        <v-select no-data-text="Нет данных" :items="points" name="point" item-text="name" item-value="id" v-model="current_point_id" label="От точки" hide-details></v-select>
                                    </v-col>

                                    <v-col cols="12" v-if="sms_message.length && current_point_id">
                                        <p class="my-0 text-wrap">
                                            <v-icon color="primary" class="mr-1">mdi-information-outline</v-icon>
                                            После отправки будет израсходовано <b>{{ Math.trunc(sms_message.length / 70.001) + 1 }}</b> сообщ. из пакета
                                        </p>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-btn color="blue" style="color:white !important" :disabled="!sms_message.length || !current_point_id || guestCopy.hide" @click="sendMessage()">
                                            <v-icon dense style="color:white !important" class="mr-2">mdi-send</v-icon>
                                            Отправить
                                        </v-btn>
                                    </v-col>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="mx-2" color="primary" text @click="guestDialog = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                guests: [],
                loading: true,
                options: {},
                search: '',

                config,
                dates: [],
                calendar: 0,
                menu_calendar: false,
                loadingEdit: false,
                headers: [],
                orders: [],
                dialogHeader: '',
                dialogContent: '',
                page: 1,
                perPage: config.paginateItemsCount,
                d1: null,
                d2: null,
                point_id: 0,
                filter_point: 0,
                filter_points: [],
                ordersDialog: false,
                guestDialog: false,
                loadingOrders: false,
                detailsPointIds: [],
                detailsGuestId: 0,
                guestCopy: {},
                sms_subscriber: '',
                sms_message: '',
                current_point_id: null,
                currentGuest: null,
                points: [],
                pagination: {},
                totalGuests: 0,
                totalCost: 0,
                firstLoad: true,
                smsStatus: 0,
                toggleGuests: 1,
            }
        },
        props: {
            data: {
                type: Object,
            },
        },
        watch: {
            options: {
                handler() {
                    if (!this.firstLoad) {
                        this.updateData()
                    }
                    this.firstLoad = false
                },
                deep: true,
            },
            search: {
                handler() {
                    this.options.page = 1
                    this.updateData()
                },
                deep: true,
            },
        },
        mounted() {
            console.log('guests rate start!')
            // console.log('this.data.search', this.data.search)

            if (this.data && this.data.search) {
                this.search = this.data.search
                // this.data.search = ''
            } else {
                this.updateData()
            }
        },

        computed: {
            visibleOrders() {
                return this.orders.slice((this.page - 1) * this.perPage, this.page * this.perPage)
            },
        },
        methods: {
            formatPrice(value) {
                let price = 0
                if (value) price = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                return price
            },

            headersUpdate() {
                if ((this.$store.state.userIsPoint && this.$store.state.user.point_id) || this.filter_point) {
                    this.headers = [
                        {
                            text: 'Телефон',
                            value: 'phone',
                            sortable: true,
                        },
                        {
                            text: 'Имя',
                            value: 'name',
                            sortable: true,
                        },
                        {
                            text: 'Первый визит',
                            value: 'date_create',
                            sortable: true,
                        },
                        {
                            text: 'Последний визит',
                            value: 'date_visit',
                            sortable: true,
                        },
                        {
                            text: this.$store.state.userIsPoint ? 'Заказы у меня' : 'Заказы точки',
                            value: 'count',
                            sortable: false,
                            // sortable: true,
                        },
                        {
                            text: this.$store.state.userIsPoint ? 'Чек у меня' : 'Чек точки',
                            value: 'cost',
                            sortable: false,
                            // sortable: true,
                        },
                        {
                            text: 'Заказы у других',
                            value: 'other_count',
                            sortable: false,
                            // sortable: true,
                        },
                        {
                            text: 'Чек у других',
                            value: 'other_cost',
                            sortable: false,
                            // sortable: true,
                        },
                        {
                            text: 'Заказы всего',
                            value: 'order_count',
                            sortable: true,
                        },
                        {
                            text: 'Чек всего',
                            value: 'pay_cost',
                            sortable: true,
                        },
                    ]
                } else {
                    this.headers = [
                        {
                            text: 'Телефон',
                            value: 'phone',
                            sortable: true,
                        },
                        {
                            text: 'Имя',
                            value: 'name',
                            sortable: true,
                        },
                        {
                            text: 'Первый визит',
                            value: 'date_create',
                            sortable: true,
                        },
                        {
                            text: 'Последний визит',
                            value: 'date_visit',
                            sortable: true,
                        },
                        {
                            text: 'Заказы всего',
                            value: 'order_count',
                            sortable: true,
                        },
                        {
                            text: 'Чек всего',
                            value: 'pay_cost',
                            sortable: true,
                        },
                    ]
                    if (!this.$store.state.userIsPoint) {
                        this.headers.push({
                            text: 'Точки',
                            value: 'points',
                            sortable: false,
                        })
                    }
                }

                return this.headers
            },

            onClickGuest(guest) {
                if (this.ordersDialog) return
                this.guestDialog = true
                console.log('onClickGuest', guest)
                this.currentGuest = guest
                this.guestCopy = JSON.parse(JSON.stringify(this.currentGuest))
                let guest_name
                if (guest.name) {
                    guest_name = guest.name
                } else {
                    guest_name = guest.phone
                }
                this.dialogHeader = `Гость "${guest_name}"`
                this.sms_subscriber = this.currentGuest.phone
            },

            sendMessage() {
                if (this.$store.state.userIsPoint) {
                    this.current_point_id = this.$store.state.user.point_id
                }
                this.$store
                    .dispatch('sms_send', {
                        point_id: this.current_point_id,
                        phone: this.sms_subscriber,
                        text: this.sms_message,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.$notify.toast(res.data.description, { color: 'warning', timeout: 4000 })
                            this.smsStatus = 0
                            this.smsStatusCheck(res.data.message_id)
                        } else {
                            this.$notify.toast('Сообщение не отправлено. ' + res.data.description, { color: 'error', timeout: 4000 })
                        }
                    })
                    .catch(err => {
                        this.$notify.toast('Сообщение не отправлено.', { color: 'error', timeout: 4000 })
                        console.log(err)
                    })
            },

            smsStatusCheck(message_id) {
                setTimeout(() => {
                    this.$store
                        .dispatch('sms_status', {
                            point_id: this.current_point_id,
                            message_id,
                        })
                        .then(res => {
                            if (res.data.success) {
                                if (res.data.msg != 'ok') {
                                    if (this.smsStatus == 0) {
                                        this.$notify.toast(res.data.description, { color: 'warning', timeout: 15000 })
                                    }
                                    this.smsStatus = 1
                                    this.smsStatusCheck(message_id)
                                } else {
                                    this.$notify.toast(res.data.description, { color: 'success', timeout: 4000 })
                                }
                            } else {
                                console.log('sms_status fail')
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }, 2000)
            },

            onClickOrdersDetails(guest, count, other_flag) {
                console.log('onClickOrdersDetails', guest)
                this.page = 1
                this.orders = []
                let guest_name
                if (guest.name) {
                    guest_name = guest.name
                } else {
                    guest_name = guest.phone
                }
                this.dialogHeader = `Путь гостя "${guest_name}" - заказы (${count})`
                this.dialogContent = this.$t('common.loading')

                this.detailsPointIds = guest.point_ids

                this.detailsGuestId = guest.id
                this.updateOrdersDetails()
                this.ordersDialog = true
            },

            onClickChangeDate(arg) {
                this.d1 = this.getDate1StringFromDate(this.dates[0])
                this.d2 = this.getDate2StringFromDate(this.dates[1])
                this.updateData()
            },

            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String()
                this.d2 = this.getDate2String()
                this.updateData()
            },

            onClickYesterday() {
                this.dates[0] = moment()
                    .subtract(1, 'd')
                    .format('YYYY-MM-DD')
                this.dates[1] = moment()
                    .subtract(1, 'd')
                    .format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-1)
                this.d2 = this.getDate2String(-1)
                this.updateData()
            },

            onClickMonth() {
                this.dates[0] = moment()
                    .subtract(30, 'd')
                    .format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-30)
                this.d2 = this.getDate2String()
                this.updateData()
            },

            onClickUpdate() {
                this.updateData()
            },

            onClickSave() {
                this.loadingEdit = true

                this.$store
                    .dispatch('guest_set', {
                        id: this.guestCopy.id,
                        phone: this.guestCopy.phone,
                        name: this.guestCopy.name,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.$notify.toast('Гость сохранен успешно.', { color: 'success', type: 'success', timeout: 4000 })
                            this.currentGuest.name = this.guestCopy.name
                        } else {
                            this.$notify.toast('Не удалось сохранить гостя.', { color: 'error', timeout: 4000 })
                        }
                        this.loadingEdit = false
                        this.guestDialog = false
                    })
                    .catch(err => {
                        this.$notify.toast('Не удалось сохранить гостя.', { color: 'error', timeout: 4000 })
                        this.loadingEdit = false
                    })
            },

            onClickDelete() {},

            updateOrdersDetails() {
                this.loadingOrders = true
                this.$store
                    .dispatch('orders_date', {
                        point_ids: JSON.stringify(this.detailsPointIds),
                        guest_id: this.detailsGuestId,
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            this.orders = res.data.orders
                        } else {
                            this.dialogContent = this.$t('common.loading_error', { p1: 1 })
                        }
                        this.loadingOrders = false
                    })
                    .catch(err => {
                        this.loadingOrders = false
                        console.log('point_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', { p1: 2 })
                    })
            },

            updateData() {
                console.log('updateData')
                this.loading = true
                let point_id = this.$store.state.userIsPoint ? this.$store.state.user.point_id : this.filter_point
                console.log('point_id', point_id)

                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                this.$store
                    .dispatch('points_get')
                    .then(res => {
                        if (res.data.success) {
                            this.points = res.data.points
                            if (this.$store.state.userIsPoint) {
                                this.points = this.points.filter(p => p.id == point_id)
                            }

                            this.$store
                                .dispatch('guests_rate_get', {
                                    point_id: this.toggleGuests == 2 ? 0 : point_id,
                                    sortBy,
                                    sortDesc,
                                    page,
                                    itemsPerPage,
                                    search: this.search,
                                })
                                .then(res => {
                                    if (res.data.success) {
                                        const guests = res.data.guests
                                        let totalCostCalc = 0
                                        this.totalPointCost = 0

                                        let guestHide = false

                                        for (const guest of guests) {
                                            if (point_id) {
                                                guest.date_create_rate = ''
                                                guest.date_visit_rate = ''
                                                guest.count_rate = ''
                                                guest.cost_rate = ''

                                                if (guest.options.points[point_id]) {
                                                    let date_create = guest.options.points[point_id].create
                                                    if (guest.options.points[point_id].create == guest.date_create) {
                                                        guest.date_create_rate = '⭐️'
                                                    }
                                                    guest.date_create = date_create

                                                    let date_visit = guest.options.points[point_id].visit
                                                    if (guest.options.points[point_id].visit == guest.date_visit) {
                                                        guest.date_visit_rate = '⭐️'
                                                    }
                                                    guest.date_visit = date_visit

                                                    guest.count = guest.options.points[point_id].count
                                                    guest.cost = guest.options.points[point_id].cost
                                                    if (guest.cost < 99 * guest.count) {
                                                        guest.cost = 500 * guest.count
                                                    }
                                                    if (guest.options.points[point_id].count == guest.order_count) {
                                                        guest.count_rate = '⭐️'
                                                    }
                                                    if (guest.options.points[point_id].cost == guest.pay_cost) {
                                                        guest.cost_rate = '⭐️'
                                                    }

                                                    guest.other_count = guest.order_count - guest.options.points[point_id].count
                                                    guest.other_cost = guest.pay_cost - guest.options.points[point_id].cost

                                                    this.totalPointCost += guest.pay_cost
                                                } else {
                                                    console.log('guest', guest)
                                                    guest.count = 0
                                                    guest.cost = 0
                                                    guest.other_count = guest.order_count
                                                    guest.other_cost = guest.pay_cost
                                                }

                                                if (guest.other_cost < 99 * guest.other_count) {
                                                    guest.other_cost = 500 * guest.other_count
                                                }

                                                if (guest.cost == null) {
                                                    console.error('cost null', guest.id, guest)
                                                }
                                                if (guest.other_cost == null) {
                                                    console.error('other_cost null', guest.id, guest)
                                                }

                                                if (!guest.point_ids.includes(point_id)) {
                                                    guest.phone = 'скрыто'
                                                    guest.name = 'скрыто'
                                                    guest.hide = true
                                                    guestHide = true
                                                }
                                            } else {
                                                guest.date_create_rate = ''
                                                guest.date_visit_rate = ''
                                                guest.count_rate = ''
                                                guest.cost_rate = ''

                                                if (guest.pay_cost < 99 * guest.order_count) {
                                                    guest.pay_cost = 500 * guest.order_count
                                                }

                                                totalCostCalc += guest.pay_cost

                                                if (guest.pay_cost == null) {
                                                    console.error('pay_cost null', guest.id, guest)
                                                }

                                                let intersection = this.points.filter(p => guest.point_ids.includes(p.id)) // пересечение
                                                if (!intersection.length) {
                                                    guest.phone = 'скрыто'
                                                    guest.name = 'скрыто'
                                                    guest.hide = true
                                                    guestHide = true
                                                }
                                            }
                                            guest.points = ''
                                        }

                                        this.point_id = point_id
                                        this.headersUpdate()
                                        if (guests.length == 1 && guestHide) {
                                            this.guests = []
                                            this.totalGuests = 0
                                            this.totalCost = 0
                                        } else {
                                            this.guests = guests
                                            this.totalGuests = res.data.total
                                            this.totalCost = res.data.totalCost
                                            if (this.totalCost < totalCostCalc) {
                                                this.totalCost = totalCostCalc
                                            }
                                        }

                                        console.log('guests', guests)
                                        console.log('res.data', res.data)

                                        for (const guest of this.guests) {
                                            if (guest.point_ids.length) {
                                                for (const point_id of guest.point_ids) {
                                                    const point = this.points.find(p => p.id == point_id) // общие точки для всех
                                                    if (point) {
                                                        guest.points += point.name + ', '
                                                    } else {
                                                        guest.points += 'скрыто, '
                                                    }
                                                }
                                                guest.points = guest.points.substring(0, guest.points.length - 2)
                                            }
                                        }
                                        console.log('ok')
                                    }

                                    this.loading = false
                                })
                                .catch(err => {
                                    this.loading = false
                                    console.log(err)
                                })
                        }

                        this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                        this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                    })
                    .catch(err => {
                        this.loading = false
                        console.log('1 error', err.name, err.message)
                    })
            },

            orderCheckOwner(order) {
                return this.points.find(p => p.id == order.point_id) != null
            },

            orderListItem(order) {
                return ` <b>${order.name}</b>, ${order.pay_cost} ₽ - ${moment(order.date).format('D MMM HH:mm')} - <span style="color: ${this.orderStatusColorHtml(order.status)};">${this.orderStatusStr(order.status)}${order.date_complete ? ` (${moment(order.date_complete).format('D MMM HH:mm')})` : ''}</span><br>`
            },

            orderListHiddenItem(order) {
                return ` <b>------------ скрыто ------------</b>, ${order.pay_cost} ₽ - ${moment(order.date).format('D MMM HH:mm')} - <span style="color: ${this.orderStatusColorHtml(order.status)};">${this.orderStatusStr(order.status)}${order.date_complete ? ` (${moment(order.date_complete).format('D MMM HH:mm')})` : ''}</span><br>`
            },

            rowItemClass(guest) {
                let cl = ''
                if (guest.hide) {
                    cl += ' guest-hidden'
                } else {
                    if (this.point_id && !guest.other_count && parseInt(guest.count) == 1) {
                        // гость связан только с этой точкой 1 заказом
                        cl = 'rowItemSuccess1'
                    } else if (this.point_id && !guest.other_count && parseInt(guest.count) > 1) {
                        // гость связан только с этой точкой несколькими заказом
                        cl = 'rowItemSuccess2'
                    } else if (this.point_id && guest.other_count > 0 && parseInt(guest.count) == 1) {
                        // гость первый раз попробовал заказать в этой точке
                        cl = 'rowItemWarning'
                    } else if (!this.point_id && guest.order_count == 1) {
                        // гость первый раз заказал
                        cl = 'rowItemSuccess1'
                    } else if (!this.point_id && guest.point_ids.length == 1 && guest.order_count > 1) {
                        // гость постоянно заказывает в одной точке
                        cl = 'rowItemSuccess2'
                    }
                }
                return cl
                // return item.status == config.order.status.forming ? 'rowItemDisable' : ''
            },
        },
    }
</script>
